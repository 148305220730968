import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Advertise from './Advertise'
import DocumentMeta from 'react-document-meta';
const TermsLBD = () => {
    const meta = {
        title: 'Terms & Conditions',
        description: 'I am a description, and I can create multiple tags',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'react,meta,document,html,tags'
          }
        }
      }
  return (
    <div>
         <DocumentMeta {...meta} />
    <div className='home-container'>
        <Header />
        <div className='terms-section'>
        <h1 className='title'> 
        Terms and Conditions
 </h1>
 <h3>
 PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY

 </h3>
 <p> This Terms and Conditions agreement (the “<b>Agreement</b>”) constitute a legally binding agreement between You (“<b>You</b>”, “<b>Your</b>” or “<b>Client</b>”) and Tower Media Middle East FZ-LLC, a Free Zone Limited Liability Company organized and existing under the laws and regulations applicable in Dubai Media City, Dubai, United Arab Emirates, with its principal place of business located at Dubai Media City, Building 1, Office 405, P.O. Box 500765, Dubai, United Arab Emirates (“<b>we</b>”, “<b>us</b>”, “<b>our</b>” or “<b>TMME</b>”) for applying to the “Living bydesign” service (“<b>Service</b>”).
</p>
<p>This Agreement is an addition to the terms and conditions which also apply to your use of our Website and other platforms including, our privacy policy which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By applying to the Service, you consent to such processing, and you warrant that all data provided by you is accurate.
</p>
<p>This Agreement is subject to periodic review and may be updated, revised, or amended at our sole discretion without prior notice. Any updates or changes to the Agreement shall have immediate effect. It is the responsibility of the user or licensee to be regularly and well informed about the latest status of this Agreement.
</p>
 < div className='terms'>
    <div className='term'>
    <h4>
    REGISTERING AND CLIENT INFORMATION:
 </h4>
<p>By applying and/or registering to the Service, you agree with and accept all of the terms and conditions contained in this Agreement. This agreement is provided to you and concluded in English. We recommend that you store or print-off a copy of the Agreement for your records.
If you do not agree to the terms of this Agreement, please refrain from applying or registering to the Service.
</p>
<br></br>
<p><b>Client Information:</b> Pursuant to other requirements, You are responsible to provide us with your full name as it appears on your passport (or valid Emirates I.D.), age, phone number and email, and all other documents that we may require later for the purpose of providing the Service under this Agreement.
</p>
 
    </div>
 
    <div className='term'>

<h4>
PAYMENT AND FEE:
 </h4>
<p>
<u><b>Fee:</b></u> in consideration for the Services provided by us, You shall pay the required Fee appearing upon the application form. 

    </p>

<p>
<u><b>Full Payment:</b></u> The payment shall be paid in two (2) installments, 50% upon the signature/acceptance of the application for and this Agreement, and the remaining 50% on the remaining 50% upon the handover of a final, approved concept.

    </p>
    <p><u><b>Third-Party Payments:</b></u> The Client shall assume sole responsibility for any third-party purchases and associated payments, including but not limited to lighting fixtures, furniture, appliances, and any other items or services required for the project.
</p>
<p><u><b>Additional Payment:</b></u> Any additional requests, services or expenses made by the Client beyond the scope of the agreed-upon services shall be subject to separate charges. We reserve the right to bill the Client separately for any additional services, materials, or expenses incurred as a result of such requests. These additional charges shall be communicated to the Client in advance and must be approved by the Client before implementation. Failure to approve these additional charges may result in delay or cancellation of the additional services requested.
</p>
<p><u><b>Form of Payment:</b></u> The payment shall be made online using credit cards or debit cards only.
</p>
<p><u><b>Payment Processing:</b></u>  Payments will be processed by a third-party payment processor, Mamo Limited, Unit GA-00-SZ-L1-RT-196, Level 1, Gate Avenue - South Zone, Dubai International Financial Centre, Dubai, United Arab Emirates and in accordance with its standard terms and conditions: https://www.mamopay.com/legal/terms-business and Privacy Terms and Conditions: https://www.mamopay.com/legal/privacy . Mamo Limited is regulated by the Dubai Financial Service Authority (“DFSA”).
</p>
    </div>

    <div className='term'>

<h4>
CANCELLATION AND REFUNDS:
 </h4>
<p>
Should the Client wish to cancel the Service after signing this Agreement and submitting the application for, the following cancellation charges would apply: 20% of the total Fee shall be deducted if the cancellation occurred within 48 hours from the date of signature. After the elapse of the 48 hour period, cancellation shall be no longer allowed and the fee paid by the Client shall not be refundable for any reason whatsoever. Cancellation will only be accepted in writing, Email your cancellation request to lbd@alaan.tv with your full name and Passport or ID number. If you do not receive an acknowledgment within 12 hours, please call 044277888 during working hours (8:30-17:30 Dubai time) to ensure that we have received your cancellation request.


    </p>
<br />
<p>In the event the Client’s registration was canceled by us, we shall refund the Fee paid by the Client to us.
</p>


    </div>
    <div className='term'>

<h4>
OUR SERVICE:

 </h4>
<p>
Living bydesign is a comprehensive digital platform that offers a sophisticated interior design consultation service, tailored to meet the unique needs and preferences of each client. Our innovative approach revolves around delivering a computer-generated concept that serves as the foundation for establishing a cohesive design direction. This concept encapsulates the envisioned aesthetic, mood, and overall ambiance of the desired space, providing clients with a tangible vision to guide the design process.
    </p>
    <br/>
<p>Prior to any physical deliverables, the client will first be provided with an in-depth consultation to fully explore their vision, personal taste & requirements conducted at their property (to be designed).
</p>
<br/>
<p>In addition to the conceptual framework, Living bydesign provides clients with a personalized shopping list of furniture and decor items sourced from a curated selection of third-party vendors. These recommendations are carefully chosen to align with the client's style preferences, budget considerations, and functional requirements, ensuring a harmonious and visually appealing interior scheme. For the avoidance of doubt, the Fee mentioned in this Agreement is for the design only and does not include the prices of  lighting, furniture, appliances, delivery, handymen  and any other item or service required for the execution of the project.
</p>
<br/>
<p>Furthermore, Living bydesign enhances the design experience by offering an overhead floor plan that offers practical guidance on spatial arrangement and layout optimization.
</p>
<br/>
<br/>
<br/>
<p>The Client may issue us reasonable instructions regarding the services provided under this agreement. However, we reserve the right to evaluate and determine the feasibility and practicality of such instructions. We shall not be obliged to comply with any instruction that, in our professional judgment, is unreasonable, impractical, or outside the scope of the agreed-upon services. In such cases, we shall inform the Client of our decision and provide reasons for non-compliance. The Client acknowledges and agrees that TMME’s professional judgment shall prevail in determining the suitability and implementation of any instructions issued.
</p>

    </div>
    <div className='term'>

<h4>
LICENSING AND RIGHTS:

 </h4>
<p>
We retain full ownership of the copyrights to all designs and drawings produced during the provision of Services. However, upon the Client's full payment of all outstanding dues, we grant the Client a perpetual, non-transferrable, non-sublicensable and non-exclusive license to utilize these drawings for their intended purposes. This license permits the Client to use the designs and drawings indefinitely, subject to the terms and conditions outlined in this Agreement.
In the event that the Client fails to remit the full amount as specified, TMME reserves the right to withhold the license and the Client shall not be entitled to utilize any of the drawings and designs provided by TMME.



    </p>

<p>
<b>Intellectual Property Rights:</b> We are the owner or the licensee of all intellectual property rights and materials published on all of our platforms and websites (“Our Platforms”). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. Except as expressly provided in this Agreement, TMME and its licensors exclusively own all right, title and interest in and to Our Platforms including all associated intellectual property rights. You may not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the website, Services or TMME’s Content.
    </p>
    <br />
    <p>
    The trademarks, service marks, and logos contained on Our Platforms are owned by us. You cannot use, copy, edit, vary, reproduce, publish, display, distribute, store, transmit, commercially exploit or disseminate these trademarks without the prior written consent of us.


    </p>
    </div>
    <div className='term'>

<h4>
LIABILITY

 </h4>
<p>
The Client Acknowledges and confirms that:
    </p>
    <ul style={{paddingLeft: "60px"}}> <li>The delivered concept and Services are not a definitive representation of the furniture, fixtures, decor, and furnishings listed in the provided shopping list.
</li>
<li>Certain products listed in the shopping list may not be physically available in the U.A.E and may require international purchase and delivery.
</li>
<li>The delivered concept and Services serve as a representation of the energy, atmosphere, and overall design aesthetic of the redesigned space, and not as a definitive representation of the space's visual end result.
</li>
<li>We reserve the right to utilize, display, and reproduce the designs produced through Living bydesign for the Client for marketing and promotional purposes, including but not limited to, showcasing the work on our website, social media platforms, promotional materials, and presentations.
</li>
<li>We reserve the right to use any and all Client testimonials pertaining to the service, it’s deliverables, and its process for marketing and promotional purposes, including but not limited to publishing the testimonials on our website, social media platforms, promotional materials, and presentations.
</li>
<li>TMME shall not be responsible for the quality, condition, delivery, installation, damages, renovations, dissatisfactions, returns or any other aspect pertaining to third-party purchases made by the Client.
</li>
<li>TMME shall not be held responsible for any instructions, changes and/or variations made or instructed by the Client.
</li>
<li>TMME shall not be held liable for any consequences arising from the use of information or designs prepared by TMME.
</li>
</ul>

    
<p>
    <br />
Furthermore, the Client acknowledges and releases TMME from any liability pertaining if:
    </p>
    <ul style={{paddingLeft: "60px"}}> 
    <li>The suggested furniture does not match the existing look of the room (wall color, floor tiling, wall material), completely complement or match each other, has a manufacturing defect, comfort issue or deteriorate over time.

</li>
<li>The suggested furniture is unable to be delivered into the property due to logistical constraints.

</li>
<li>The room's lighting does not reflect the lighting shown in the concept.

</li>
<li>The Client loses satisfaction with the remodel over time.

</li>
<li>The Client is unable to afford all of the suggested furniture, materials, and installation costs.

</li>

</ul>

    </div>
    <div className='term'>

<h4>
WARRANTIES
 </h4>
<p>
The Client hereby warrants that:
    </p>
<ul style={{paddingLeft: "60px"}}>
    <li>S/He has full right, power and authority to enter into this Agreement and to grant any and all rights granted hereunder;
</li>
    <li>It has secured and obtained third-party rights and shall provide TMME with all necessary permissions, authorizations and consents to allow TMME to perform its Services under this Agreement;
</li>
    <li>all information, records and data provided to TMME will be accurate, full and up to date;
</li>
    <li>S/He shall at all times comply with all relevant laws and regulations applicable in the UAE.
</li>
    <li>S/He has no right to sell, license, distribute or otherwise deal in or dispose in the drawings and Services or any part thereof without prior written consent of TMME. 
</li>
</ul>
    </div>
    <div className='term'>

<h4>
GENERAL TERMS


 </h4>
<p>
<b>Non-disclosure:</b> the Client shall not, during the course of this agreement, nor at any time thereafter, directly or indirectly, in public or private, in any manner or in any medium whatsoever, make any comments, writings, remarks or other expressions that would, or could be construed to, defame TMME’s members, employees, partners, or associates and our reputation. The Client also agrees not to disclose any information regarding the Services that are or were provided under this Agreement.
    </p>

    </div>
    <div className='term'>

<h4>
Confidentiality 



 </h4>
<p>
 
The Client hereby agrees and acknowledges that the terms and conditions contained within this Agreement and all information, know-how and trade secrets relating to TMME and the Services (including information relating to budgets and the existence of this Agreement), is of a confidential nature and the Client shall maintain the confidentiality of such information (herein referred to as the “Confidential Information”) except:

    </p>
<ul style={{paddingLeft: "60px"}}>
    <li>information that, at the time of disclosure, is in the public domain;
</li>
    <li>information that, after the time of disclosure, becomes part of the public domain by publication or otherwise, except by breach of this Agreement;
</li>
    <li>information that must be disclosed in accordance with an order issued by a competent court or a law or regulation issued by a competent government authority.
</li>
</ul>
<p>This clause related to confidentiality shall survive the termination or expiry of this Agreement and remain in operation and have full legal effect indefinitely.
</p>
<br />
<p><b>Relationship:</b> The relationship of the Client in relation to TMME is that of independent contractor, with no authority in any way to enter into any contract or debt in the name of TMME or to bind or commit TMME to any agreement of any kind or to assume any liabilities of any nature in the name of or on behalf of TMME. At no time and under no circumstances will the Client claim that he/she is an agent, a partner or an employee of TMME under the United Arab Emirates labor law or any other law whatsoever, neither shall TMME pay any contributions to social security, health and medical insurance, unemployment and workers’ compensation, and any other applicable taxes whether federal, state, or local, nor provide any other contributions or benefits which might be expected in an employer-employee relationship.</p>
<p><b>Assignment: </b> You may not transfer to anyone else, either temporarily or permanently, any rights to use the Service or any part of the Service. Any attempt by you to do so is void. We may assign, transfer, delegate and/or grant all or any part of its rights, privileges and properties hereunder to any person or entity.</p>
<p><b>Force Majeure: </b>  In no event shall You or us be in default of this Agreement to the extent that the Client or TMME is unable to commence or complete its obligations or is unable to comply with any other term of this Agreement by reason of any fire, earthquake, flood, pandemic, epidemic, accident, explosion, casualty, strike, lock out, civil disturbance, act of public enemy, natural catastrophe, embargo, war, or any other cause whatsoever beyond the control of the said Party.
</p>
<p><b>Survival:</b> Rights, licensing and obligations under this Agreement should survive and will remain in full effect after termination or expiration of the Registration and Service.

</p>
<p><b>Governing Law:</b> This Agreement is governed by and construed according to the laws of the United Arab Emirates as applied in the Emirate of Dubai. Should a dispute arise, the Client and TMME will meet and attempt to come to an amicable agreement. If they fail, the dispute will be referred to a committee comprised of representatives of both parties. If they fail to reach a resolution within 7 days or the parties fail to appoint such a committee then the dispute will be referred to arbitration with the exclusive jurisdiction of the Dubai International Arbitration Centre (“<b>DIAC”</b>).
All arbitration proceedings are to take place in Dubai. Any meetings will be conducted in English except to the extent that compliance with the United Arab Emirates formalities may so require.
The procedure of any arbitration will be governed by the rules and procedures approved by DIAC and as required by the laws relating to arbitration in the United Arab Emirates and the Emirate of Dubai and, subject to the foregoing, as otherwise set by the arbitrators. The majority decision of the arbitration panel will be final, binding and enforceable upon the Parties. The decision of such arbitration shall be notified to the parties within six (6) months of the reference to arbitration being made.
The fees, expenses and costs of any arbitration will be borne equally by the parties unless the arbitral award provides otherwise.


</p>
    <br/>
    <p>By submitting your application, you confirm that you are over 18 years of age.
</p>
<br/>
    <p>By clicking “<b>I Agree</b>” and submitting your information when you register your application, you are entering into a legally binding agreement with Tower Media Middle East FZ-LLC, and therefore you agree to be bound by the terms and conditions set forth herein in this Agreement. <b>if you do not accept these terms and conditions, you must not apply or submit your application to us.</b>

</p>
    </div>
    <div className='term'>


<p>
Cancellation fees will apply. Please check the cancellation terms.





    </p>

    </div>

    <p>
 If you have any questions or concerns, please contact us at <a href="mailto:info@alaan.tv" >
          <span className='info-text'>info@alaan.tv</span>
      
        </a>
    </p>
 </div>
 
        </div>
        <Advertise />
    </div>
    <Footer />
    </div>
  )
}

export default TermsLBD